import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import '../assets/login/login.css';
import '../assets/login/loginStyle.css';
import { ToastContainer, toast } from 'react-toastify';
import { createBrowserHistory } from 'history';

export const Login = () => {

    const [email,setEmail] = useState();
    const [password,setPassword] = useState();
    var history = createBrowserHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            const res = await axios.post('http://api.kuzeysoftware.com/auth/signin', {email,password}); 
            if(res.data.accessToken){
                const notify = () => toast.success("Giriş başarılı!");
                notify();
                setTimeout(() => {
                    history.go(`/admin/panel?token=${res.data.accessToken}`);
                }, 2000);
            }           
        }catch(err){
            const notify = () => toast.error("Giriş başarısız!");
            notify();
        }
    }

  return (
   <div className="form-body">
    <Helmet>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" />
    <title>Admin Login - Kuzey Software</title>
    </Helmet>
     
        <div className="row">
            <ToastContainer  closeButton={false} />
          <div className="img-holder">
            <div className="bg" />
            <div className="info-holder">
              <img src="https://i.ibb.co/9b48rY1/kuzey-white.png" alt="" />
            </div>
          </div>
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                <div className="page-links">
                  <a className="active">Login</a>
                </div>
                <form>
                  <input onChange={(e) => setEmail(e.target.value)} className="form-control" type="text" name="username" placeholder="E-mail Address" required />
                  <input onChange={(e) => setPassword(e.target.value)} className="form-control" type="password" name="password" placeholder="Password" required />
                  <div className="form-button">
                    <button onClick={(e) => handleSubmit(e)}  className="ibtn">Login</button> <a href="forget7.html">Forget password?</a>
                  </div>
                </form>
          
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
