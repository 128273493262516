import React, { useState } from 'react'
import Typewriter from 'typewriter-effect/dist/core';
import able from '../assets/images/portfolio/able.png';
import acc from '../assets/images/portfolio/acc.png';
import orient from '../assets/images/portfolio/orient.png';
import sayob from '../assets/images/portfolio/sayob.png';
import sayoter from '../assets/images/portfolio/sayoter.png';
import sayokit from '../assets/images/portfolio/sayokit.png';
import astromerwish from '../assets/images/portfolio/astromerwish.png';
import instasave from '../assets/images/portfolio/instasave.png';


export const Main = () => {

  useState(() => {
    setTimeout(() => {
      var app = document.getElementById('app');
      let typewriter = new Typewriter(app, {
        loop: false,
        delay: 85
      });
      typewriter
        .pauseFor(300)
        .typeString('<<code><span style="color: #FF5733">html</span> <span style="color: #4BDA5C">lang</span><span style="color: #FF5733">=</span><span style="color: #FFC300">"tr"</span></code>> ')
        .pauseFor(300)
        .typeString('<br>  <span style="margin-left: 20px;"><<code><span style="color: #FF5733">body</span></code>>')
        .pauseFor(300)
        .typeString('<br> <span style="margin-left: 40px;"> <<code><span style="color: #FF5733">h1</span></code>>')
        .pauseFor(300)
        .typeString('<br> <span style="margin-left: 90px;"> <code><span style="color: #fff">Merhaba Dünya!</span></code>')
        .pauseFor(700)
        .typeString('<br> <span style="margin-left: 40px;"> <<code><span style="color: #FF5733">/h1</span></code>> ')
        .pauseFor(300)
        .typeString('<br> <span style="margin-left: 20px;"> <<code><span style="color: #FF5733">/body</span></code>> ')
        .pauseFor(300)
        .typeString('<br><<code><span style="color: #FF5733">/html</span></code>> ')
        .pauseFor(1000)
        .start()
    }, 1700);

  }, [])


  return (
    <main id="content" className="site-main post-515 page type-page status-publish hentry" role="main">
      <div className="page-content">

        <div data-elementor-type="wp-page" data-elementor-id={515} className="elementor elementor-515">
          <div className="elementor-inner">
            <div className="elementor-section-wrap">
              <section className="elementor-section elementor-top-section elementor-element elementor-element-997dc28 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="997dc28" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-672ab55" data-id="672ab55" data-element_type="column">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-2294253 elementor-widget elementor-widget-spacer" data-id={2294253} data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner" />
                              </div>
                            </div>
                          </div>
                          <div className="mycont">
                            <div className="col-lg-6">
                              <div className="elementor-element elementor-element-0395a21 elementor-invisible elementor-widget elementor-widget-heading" data-id="0395a21" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:&quot;400&quot;}" data-widget_type="heading.default">
                                <div className="elementor-widget-container">
                                  <h3 className="elementor-heading-title elementor-size-default">YARATICI ZİHİN, MODERN PROJELER.</h3>		</div>
                              </div>
                              <div className="elementor-element elementor-element-939e142 elementor-invisible elementor-widget elementor-widget-heading" data-id="939e142" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:&quot;600&quot;}" data-widget_type="heading.default">
                                <div className="elementor-widget-container">
                                  <h1 className="elementor-heading-title elementor-size-default">Sizin dilinizde<br />yazılım</h1>		</div>
                              </div>


                            </div>
                            <div className="elementor-heading-title x" style={{ fontSize: '18px', marginTop: "22px" }}>
                              <div id="app"></div>
                            </div>
                          </div>

                          <div className="elementor-element elementor-element-ae3ef13 elementor-mobile-align-left elementor-invisible elementor-widget elementor-widget-button" data-id="ae3ef13" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:&quot;800&quot;}" data-widget_type="button.default">
                            <div className="elementor-widget-container">

                            </div>
                          </div>
                          <div className="elementor-element elementor-element-9d4f602 elementor-widget elementor-widget-spacer" data-id="9d4f602" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner" />

                              </div>
                            </div>
                          </div>
                          <section className="elementor-section elementor-inner-section elementor-element elementor-element-fa0fc7c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="fa0fc7c" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-12e4c22 elementor-invisible" data-id="12e4c22" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                  <div className="elementor-column-wrap elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div className="elementor-element elementor-element-0750fff elementor-view-default elementor-widget elementor-widget-icon" data-id="0750fff" data-element_type="widget" data-widget_type="icon.default">
                                        <div className="elementor-widget-container">
                                          <div className="elementor-icon-wrapper">
                                            <div className="elementor-icon">
                                              <i aria-hidden="true" className="icon icon-paper-plane" />			</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="elementor-element elementor-element-dfb8619 elementor-widget elementor-widget-heading" data-id="dfb8619" data-element_type="widget" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                          <h4 className="elementor-heading-title elementor-size-default">Modern Konsept</h4>		</div>
                                      </div>
                                      <div className="elementor-element elementor-element-42bb693 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="42bb693" data-element_type="widget" data-widget_type="divider.default">
                                        <div className="elementor-widget-container">
                                          <div className="elementor-divider">
                                            <span className="elementor-divider-separator">
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="elementor-element elementor-element-5825d7b elementor-widget elementor-widget-heading" data-id="5825d7b" data-element_type="widget" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                          <h5 className="elementor-heading-title elementor-size-default">Müşterilerimizin ihtiyaçlarına en iyi çözümleri, teknolojinin tüm yeniliklerini kullanarak sağlıyoruz.​</h5>		</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-a17c770 elementor-invisible" data-id="a17c770" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:&quot;200&quot;}">
                                  <div className="elementor-column-wrap elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div className="elementor-element elementor-element-01e2c05 elementor-view-default elementor-widget elementor-widget-icon" data-id="01e2c05" data-element_type="widget" data-widget_type="icon.default">
                                        <div className="elementor-widget-container">
                                          <div className="elementor-icon-wrapper">
                                            <div className="elementor-icon">
                                              <i aria-hidden="true" className="icon icon-brain" />			</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="elementor-element elementor-element-e5f2988 elementor-widget elementor-widget-heading" data-id="e5f2988" data-element_type="widget" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                          <h4 className="elementor-heading-title elementor-size-default">Büyük Fikirler</h4>		</div>
                                      </div>
                                      <div className="elementor-element elementor-element-83167ac elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="83167ac" data-element_type="widget" data-widget_type="divider.default">
                                        <div className="elementor-widget-container">
                                          <div className="elementor-divider">
                                            <span className="elementor-divider-separator">
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="elementor-element elementor-element-c44c3af elementor-widget elementor-widget-heading" data-id="c44c3af" data-element_type="widget" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                          <h5 className="elementor-heading-title elementor-size-default">Hayal gücümüz ve tecrübemizle start-up'larınıza her aşamada destek oluyoruz.​</h5>		</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2d048ea elementor-invisible" data-id="2d048ea" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:&quot;400&quot;}">
                                  <div className="elementor-column-wrap elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div className="elementor-element elementor-element-62cd9f3 elementor-view-default elementor-widget elementor-widget-icon" data-id="62cd9f3" data-element_type="widget" data-widget_type="icon.default">
                                        <div className="elementor-widget-container">
                                          <div className="elementor-icon-wrapper">
                                            <div className="elementor-icon">
                                              <i aria-hidden="true" className="icon icon-idea1" />			</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="elementor-element elementor-element-d499753 elementor-widget elementor-widget-heading" data-id="d499753" data-element_type="widget" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                          <h4 className="elementor-heading-title elementor-size-default">Yaratıcı Çözümler</h4>		</div>
                                      </div>
                                      <div className="elementor-element elementor-element-0b6ec97 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="0b6ec97" data-element_type="widget" data-widget_type="divider.default">
                                        <div className="elementor-widget-container">
                                          <div className="elementor-divider">
                                            <span className="elementor-divider-separator">
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="elementor-element elementor-element-f0bfa2b elementor-widget elementor-widget-heading" data-id="f0bfa2b" data-element_type="widget" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                          <h5 className="elementor-heading-title elementor-size-default">Sorunları en kısa ve en başarılı yoldan çözüyoruz.​</h5>		</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section className="elementor-section elementor-inner-section elementor-element elementor-element-137887e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="137887e" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInDown&quot;,&quot;animation_delay&quot;:&quot;200&quot;}">
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a17c445 elementor-invisible" data-id="a17c445" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                  <div className="elementor-column-wrap elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div className="elementor-element elementor-element-36b02cf elementor-widget elementor-widget-spacer" data-id="36b02cf" data-element_type="widget" data-widget_type="spacer.default">
                                        <div className="elementor-widget-container">
                                          <div className="elementor-spacer">
                                            <div className="elementor-spacer-inner" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-576e95c" data-id="576e95c" data-element_type="column">
                                  <div className="elementor-column-wrap">
                                    <div className="elementor-widget-wrap">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elementor-section elementor-top-section elementor-element elementor-element-1308143 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id={1308143} data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e1f279f" data-id="e1f279f" data-element_type="column">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <section className="elementor-section elementor-inner-section elementor-element elementor-element-e68c82d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e68c82d" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-47961fd" data-id="47961fd" data-element_type="column">
                                  <div className="elementor-column-wrap elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div className="elementor-element elementor-element-3f9e037 elementor-hidden-phone elementor-widget-divider--view-line elementor-invisible elementor-widget elementor-widget-divider" data-id="3f9e037" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:&quot;800&quot;}" data-widget_type="divider.default">
                                        <div className="elementor-widget-container">
                                          <div className="elementor-divider">
                                            <span className="elementor-divider-separator">
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4bb8357" data-id="4bb8357" data-element_type="column">
                                  <div className="elementor-column-wrap elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div className="elementor-element elementor-element-b0c33f0 elementor-invisible elementor-widget elementor-widget-heading" data-id="b0c33f0" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:&quot;200&quot;}" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                          <h2 className="elementor-heading-title elementor-size-default">Hizmetlerimiz</h2>		</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <div className="elementor-element elementor-element-826cce7 elementor-invisible elementor-widget elementor-widget-heading" data-id="826cce7" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:&quot;400&quot;}" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elementor-section elementor-top-section elementor-element elementor-element-9b30521 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9b30521" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-bf38615 elementor-invisible" data-id="bf38615" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:&quot;200&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-5aa6d2f elementor-view-default elementor-widget elementor-widget-icon" data-id="5aa6d2f" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-chart2" />			</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-ff54a83 elementor-widget elementor-widget-heading" data-id="ff54a83" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">Digital Pazarlama</h2>		</div>
                          </div>
                          <div className="elementor-element elementor-element-8f6fe45 elementor-widget elementor-widget-heading" data-id="8f6fe45" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">Dijital dünyanın gereksinimlerini ve global/lokal sosyolojisini çok iyi biliyoruz ve hızla değişen bu dünyayı durmaksızın analiz ediyoruz.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-48cd3a7 elementor-invisible" data-id="48cd3a7" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:&quot;400&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-5e3ee13 elementor-view-default elementor-widget elementor-widget-icon" data-id="5e3ee13" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-users1" />	</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-f838d5d elementor-widget elementor-widget-heading" data-id="f838d5d" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">Arayüz Tasarımı</h2></div>
                          </div>
                          <div className="elementor-element elementor-element-f2a890a elementor-widget elementor-widget-heading" data-id="f2a890a" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">İş ihtiyaçlarınıza uyan ve kullanıcıya hitap eden tasarımlar üretiyoruz.</h5></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-4de8e08 elementor-invisible" data-id="4de8e08" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:&quot;600&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-1fdb014 elementor-view-default elementor-widget elementor-widget-icon" data-id="1fdb014" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-pencil" />			</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-39df12d elementor-widget elementor-widget-heading" data-id="39df12d" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h1 className="elementor-heading-title elementor-size-default">Web Yazılım</h1>		</div>
                          </div>
                          <div className="elementor-element elementor-element-7cc430b elementor-widget elementor-widget-heading" data-id="7cc430b" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">Firmanızın ihtiyaç duyduğu özel yazılımlar ile işlerinizi kolaylaştırıyor, tüm süreçleri raporlu ve tam zamanında teslim ediyoruz.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elementor-section elementor-top-section elementor-element elementor-element-13162b9 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="13162b9" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-9251b58 elementor-invisible" data-id="9251b58" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:&quot;200&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-39600b7 elementor-view-default elementor-widget elementor-widget-icon" data-id="39600b7" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-internet" />			</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-82d2d05 elementor-widget elementor-widget-heading" data-id="82d2d05" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">Sosyal Medya Yönetimi</h2>		</div>
                          </div>
                          <div className="elementor-element elementor-element-f40c049 elementor-widget elementor-widget-heading" data-id="f40c049" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">Kurumsal sosyal medya yönetimi ile marka ve kurumunuzun Instagram gibi mecralarda reklam ve yönetimini sağlıyoruz.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-943c71c elementor-invisible" data-id="943c71c" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:&quot;400&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-3210cea elementor-view-default elementor-widget elementor-widget-icon" data-id="3210cea" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-bookmark" />			</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-d3787ee elementor-widget elementor-widget-heading" data-id="d3787ee" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">Kurumsal Kimlik Tasarımı</h2>		</div>
                          </div>
                          <div className="elementor-element elementor-element-0b25e5d elementor-widget elementor-widget-heading" data-id="0b25e5d" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">Size en iyi olanakları sağlayabilecek çok çeşitli yüksek kaliteli markalaşma ve kurumsal kimlik hizmetlerini deneyimleme fırsatına sahip olacaksınız.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-89aef8a elementor-invisible" data-id="89aef8a" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:&quot;600&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-42406d8 elementor-view-default elementor-widget elementor-widget-icon" data-id="42406d8" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-camera" />			</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-d25f323 elementor-widget elementor-widget-heading" data-id="d25f323" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">Profesyonel Fotoğraf ve Drone Çekimleri</h2>		</div>
                          </div>
                          <div className="elementor-element elementor-element-f90a20d elementor-widget elementor-widget-heading" data-id="f90a20d" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">Projelere prestij katmanın yanı sıra izleyenlere de görsel anlamda bir değer katıyoruz.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elementor-section elementor-top-section elementor-element elementor-element-b86e282 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="b86e282" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3373ec4 elementor-invisible" data-id="3373ec4" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-441a075 elementor-widget elementor-widget-spacer" data-id="441a075" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6664edd" data-id="6664edd" data-element_type="column">
                      <div className="elementor-column-wrap">
                        <div className="elementor-widget-wrap">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elementor-section elementor-top-section elementor-element elementor-element-77da178 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="77da178" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a37ecd8" data-id="a37ecd8" data-element_type="column">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <section className="elementor-section elementor-inner-section elementor-element elementor-element-38b0b5c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="38b0b5c" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-row">
                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-3c40cd5" data-id="3c40cd5" data-element_type="column">
                                  <div className="elementor-column-wrap elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div className="elementor-element elementor-element-f1d6d97 elementor-hidden-phone elementor-widget-divider--view-line elementor-invisible elementor-widget elementor-widget-divider" data-id="f1d6d97" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:&quot;800&quot;}" data-widget_type="divider.default">
                                        <div className="elementor-widget-container">
                                          <div className="elementor-divider">
                                            <span className="elementor-divider-separator">
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e245c00" data-id="e245c00" data-element_type="column">
                                  <div className="elementor-column-wrap elementor-element-populated">
                                    <div className="elementor-widget-wrap">
                                      <div className="elementor-element elementor-element-76dde55 elementor-invisible elementor-widget elementor-widget-heading" data-id="76dde55" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:&quot;200&quot;}" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                          <h2 className="elementor-heading-title elementor-size-default">Çalışmalar</h2>		</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <div className="elementor-element elementor-element-78286a5 elementor-invisible elementor-widget elementor-widget-heading" data-id="78286a5" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;,&quot;_animation_delay&quot;:&quot;400&quot;}" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h3 className="elementor-heading-title elementor-size-default">TAMAMLANAN PROJELER</h3>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3a1b5d0" data-id="3a1b5d0" data-element_type="column">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-d60d075 elementor-align-right elementor-mobile-align-center elementor-invisible elementor-widget elementor-widget-button" data-id="d60d075" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;600&quot;}" data-widget_type="button.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-button-wrapper">
                                <a href="/calismalar" className="elementor-button elementor-size-md" role="button">
                                  <span className="elementor-button-content-wrapper">
                                    <span className="elementor-button-text">Tümünü Gör</span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elementor-section elementor-top-section elementor-element elementor-element-9ee1349 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="9ee1349" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-eb06e50" data-id="eb06e50" data-element_type="column">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-2acc5cf ekit-image-accordion-hover ekit-image-accordion-horizontal elementor-invisible elementor-widget elementor-widget-elementskit-image-accordion" data-id="2acc5cf" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;400&quot;}" data-widget_type="elementskit-image-accordion.default">
                            <div className="elementor-widget-container">
                              <div className="ekit-wid-con">        <div className="ekit-image-accordion elementskit-image-accordion-wraper">
                                <input type="radio" name="ekit_ia_2acc5cf" id="ekit_ia_2acc5cf_1" className="elementskit-single-image-accordion--input" hidden />
                                <label htmlFor="ekit_ia_2acc5cf_1" className="elementskit-single-image-accordion ekit-image-accordion-item" style={{ backgroundImage: `url(${able})` }}>
                                  <span className="elementskit-accordion-content">
                                    <span className="elementskit-accordion-title-wraper">
                                      <span className="elementskit-accordion-title ">
                                        Able Marine
                                      </span>
                                    </span>
                                  </span>
                                </label>
                                <input type="radio" name="ekit_ia_2acc5cf" id="ekit_ia_2acc5cf_2" className="elementskit-single-image-accordion--input" hidden />
                                <label htmlFor="ekit_ia_2acc5cf_2" className="elementskit-single-image-accordion ekit-image-accordion-item" style={{ backgroundImage: `url(${instasave})` }}>
                                  <span className="elementskit-accordion-content">
                                    <span className="elementskit-accordion-title-wraper">
                                      <span className="elementskit-accordion-title ">
                                        Insta Save Tool
                                      </span>
                                    </span>
                                  </span>
                                </label>
                                <input type="radio" name="ekit_ia_2acc5cf" id="ekit_ia_2acc5cf_3" className="elementskit-single-image-accordion--input" hidden />
                                <label htmlFor="ekit_ia_2acc5cf_3" className="elementskit-single-image-accordion ekit-image-accordion-item" style={{ backgroundImage: `url(${orient})` }}>
                                  <span className="elementskit-accordion-content">
                                    <span className="elementskit-accordion-title-wraper">
                                      <span className="elementskit-accordion-title ">
                                        Orient Ship Management
                                      </span>
                                    </span>
                                  </span>
                                </label>
                                <input type="radio" name="ekit_ia_2acc5cf" id="ekit_ia_2acc5cf_5" className="elementskit-single-image-accordion--input" hidden />
                                <label htmlFor="ekit_ia_2acc5cf_5" className="elementskit-single-image-accordion ekit-image-accordion-item" style={{ backgroundImage: `url(${astromerwish})` }}>
                                  <span className="elementskit-accordion-content">
                                    <span className="elementskit-accordion-title-wraper">
                                      <span className="elementskit-accordion-title ">
                                        Astromerwish
                                      </span>
                                    </span>
                                  </span>
                                </label>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elementor-section elementor-top-section elementor-element elementor-element-2f663e3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2f663e3" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">

                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c50e88d" data-id="c50e88d" data-element_type="column">
                      <div className="elementor-column-wrap">
                        <div className="elementor-widget-wrap">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>


              <section className="elementor-section elementor-top-section elementor-element elementor-element-0eb715c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="0eb715c" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3e92ae3" data-id="3e92ae3" data-element_type="column">
                      <div className="elementor-column-wrap">
                        <div className="elementor-widget-wrap">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>


            </div>
          </div>
        </div>
        <div className="post-tags">
        </div>
      </div>
      <section id="comments" className="comments-area">
      </section>{/* .comments-area */}
    </main>
  )
}
