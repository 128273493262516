import React from 'react'
import { Footer } from '../Components/Footer'
import { Header } from '../Components/Header'
import { Works } from '../Components/Works'
import {Helmet} from 'react-helmet'

export const Portfolio = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Çalışmalar - Kuzey Software</title>
            </Helmet>
            <Header />
            <Works />
            <Footer />
        </React.Fragment>

    )
}
