import React, { useState } from 'react';
import '../assets/login/hanimisHeart.css'

export const Nesli = () => {

    const [keyCount,setKeyCount] = useState(0);
    const [text,setText] = useState("");
    const [error,setError] = useState(false);
    const [page,setPage] = useState(0)

    const handleOnKeyUp = (e) => {
        if(e.keyCode === 8 && keyCount >= 0 || e.key === 'Backspace' && keyCount >= 0){
            if(keyCount !== 0){
                setKeyCount(current => current - 1)
            }
         inputOnChange(keyCount - 1)
        }
        else{
            if(keyCount < 8 && e.key !== 'Backspace' && e.key !== 'Enter')
            setKeyCount(current => current + 1);
            inputOnChange(keyCount + 1)
        }
    }
    const inputOnChange = (keycount) => {
        switch (keycount) {
            case 0:
                setText('');
                break;
            case 1:
                setText('N')
                break;
            case 2:
                setText('Ne')
                break;
            case 3:
                setText('Nes')
                break;
            case 4:
                setText('Nesl')
                break;
            case 5:
                setText('Nesli')
                break;
            case 6:
                setText('Neslih')
                break;
            case 7:
                setText('Nesliha')
                break;
            case 8:
                setText('Neslihan')
                break;
            default:
                break;
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if(text === 'Neslihan'){
            setPage(1);
        }else {
            setError(true);
            setTimeout(() => {
            setError(false);
            }, 500);
        }
    }

  return (
    <div>
        {page === 0 &&
    <div style={{position : 'absolute',top : '40%', left : '50%', transform : 'translateX(-50%)'}}>
        <h4 style={{textAlign: 'center'}}>
            Dünyanın 8. harikası nedir?
        </h4>
        <form onSubmit={handleSubmit} style={{display: 'flex', justifyContent : 'center', flexDirection : 'column'}} >
        <input style={error ? {backgroundColor : 'red'} : null} value={text} onChange={inputOnChange} onKeyUp={(e) => handleOnKeyUp(e)} type="text" />
        <button style={error ? {marginTop : '20px',borderColor : 'red'} : {marginTop : '20px'}} type="submit">Gönder</button>
        </form>
    </div>}
        {page === 1 &&
        <svg className="heart-loader" viewBox="0 0 90 90" version="1.1">
        <g className="heart-loader__group">
          <path className="heart-loader__square" strokeWidth="1" fill="none" d="M0,30 0,90 60,90 60,30z"/>
          <path className="heart-loader__circle m--left" strokeWidth="1" fill="none" d="M60,60 a30,30 0 0,1 -60,0 a30,30 0 0,1 60,0"/>
          <path className="heart-loader__circle m--right" strokeWidth="1" fill="none" d="M60,60 a30,30 0 0,1 -60,0 a30,30 0 0,1 60,0"/>
          <path className="heart-loader__heartPath" strokeWidth="2" d="M60,30 a30,30 0 0,1 0,60 L0,90 0,30 a30,30 0 0,1 60,0" />
        </g>
      </svg>
      }
    </div>
  )
}
