import React, { useState } from 'react'
import { createBrowserHistory } from 'history';


export const Service = () => {
  var history = createBrowserHistory();
  return (
    <main id="content" className="site-main post-219 page type-page status-publish hentry" role="main">
      <div className="page-content">
        <div data-elementor-type="wp-page" data-elementor-id={219} className="elementor elementor-219">
          <div className="elementor-inner">
            <div className="elementor-section-wrap">
              <section className="elementor-section elementor-top-section elementor-element elementor-element-997dc28 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="997dc28" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-672ab55" data-id="672ab55" data-element_type="column">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-2294253 elementor-widget elementor-widget-spacer" data-id={2294253} data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner" />
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-939e142 elementor-invisible elementor-widget elementor-widget-heading" data-id="939e142" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;200&quot;}" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h1 className="elementor-heading-title elementor-size-default">Hizmetler</h1>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="elementor-section elementor-top-section elementor-element elementor-element-ff03fb8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ff03fb8" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fba9bcd" data-id="fba9bcd" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-6e1c12d elementor-view-default elementor-widget elementor-widget-icon" data-id="6e1c12d" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-chart2" />			</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-13a6df0 elementor-widget elementor-widget-heading" data-id="13a6df0" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h4 className="elementor-heading-title elementor-size-default">Dijital Pazarlama</h4>		</div>
                          </div>
                          <div className="elementor-element elementor-element-036904f elementor-widget elementor-widget-heading" data-id="036904f" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">Dijital dünyanın gereksinimlerini ve global/lokal sosyolojisini çok iyi biliyoruz ve hızla değişen bu dünyayı durmaksızın analiz ediyoruz.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-586c5a9" data-id="586c5a9" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-7b36f84 elementor-view-default elementor-widget elementor-widget-icon" data-id="7b36f84" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-users1" />    	</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-1f6fb67 elementor-widget elementor-widget-heading" data-id="1f6fb67" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h4 className="elementor-heading-title elementor-size-default">Arayüz Tasarımı</h4>		</div>
                          </div>
                          <div className="elementor-element elementor-element-360900a elementor-widget elementor-widget-heading" data-id="360900a" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">İş ihtiyaçlarınıza uyan ve kullanıcıya hitap eden tasarımlar üretiyoruz.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-879b235" data-id="879b235" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-f88fa69 elementor-view-default elementor-widget elementor-widget-icon" data-id="f88fa69" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-pencil" />				</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-02094cf elementor-widget elementor-widget-heading" data-id="02094cf" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h4 className="elementor-heading-title elementor-size-default">Web Yazılım</h4>		</div>
                          </div>
                          <div className="elementor-element elementor-element-58ec9f4 elementor-widget elementor-widget-heading" data-id="58ec9f4" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">Firmanızın ihtiyaç duyduğu özel yazılımlar ile işlerinizi kolaylaştırıyor, tüm süreçleri raporlu ve tam zamanında teslim ediyoruz.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elementor-section elementor-top-section elementor-element elementor-element-449f01c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="449f01c" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-1d3154d" data-id="1d3154d" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-ae5d3be elementor-view-default elementor-widget elementor-widget-icon" data-id="ae5d3be" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-internet" />				</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-54b25dc elementor-widget elementor-widget-heading" data-id="54b25dc" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h4 className="elementor-heading-title elementor-size-default">Sosyal Medya Yönetimi</h4>		</div>
                          </div>
                          <div className="elementor-element elementor-element-7ea9e90 elementor-widget elementor-widget-heading" data-id="7ea9e90" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">Kurumsal sosyal medya yönetimi ile marka ve kurumunuzun Instagram gibi mecralarda reklam ve yönetimini sağlıyoruz.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-6f17f08" data-id="6f17f08" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-fba25dd elementor-view-default elementor-widget elementor-widget-icon" data-id="fba25dd" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-bookmark" />				</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-75143fc elementor-widget elementor-widget-heading" data-id="75143fc" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h4 className="elementor-heading-title elementor-size-default">Kurumsal Kimlik Tasarımı</h4>		</div>
                          </div>
                          <div className="elementor-element elementor-element-ca09a28 elementor-widget elementor-widget-heading" data-id="ca09a28" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">Size en iyi olanakları sağlayabilecek çok çeşitli yüksek kaliteli markalaşma ve kurumsal kimlik hizmetlerini deneyimleme fırsatına sahip olacaksınız.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-b7c5d41" data-id="b7c5d41" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-e9cbb72 elementor-view-default elementor-widget elementor-widget-icon" data-id="e9cbb72" data-element_type="widget" data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <i aria-hidden="true" className="icon icon-camera" />			</div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-7de1924 elementor-widget elementor-widget-heading" data-id="7de1924" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h4 className="elementor-heading-title elementor-size-default">Profesyonel Fotoğraf ve Drone Çekimleri</h4>		</div>
                          </div>
                          <div className="elementor-element elementor-element-2d7a657 elementor-widget elementor-widget-heading" data-id="2d7a657" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h5 className="elementor-heading-title elementor-size-default">Projelere prestij katmanın yanı sıra izleyenlere de görsel anlamda bir değer katıyoruz.</h5>		</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elementor-section elementor-top-section elementor-element elementor-element-b86e282 elementor-section-boxed elementor-section-height-default elementor-section-height-default" ata-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3373ec4 elementor-invisible" data-id="3373ec4" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:&quot;200&quot;}">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-441a075 elementor-widget elementor-widget-spacer" data-id="441a075" data-element_type="widget" data-widget_type="spacer.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6664edd" data-id="6664edd" data-element_type="column">
                      <div className="elementor-column-wrap">
                        <div className="elementor-widget-wrap">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="elementor-section elementor-top-section elementor-element elementor-element-bf7194c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="bf7194c" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                  <div className="elementor-row">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f3b6c87" data-id="f3b6c87" data-element_type="column">
                      <div className="elementor-column-wrap elementor-element-populated">
                        <div className="elementor-widget-wrap">
                          <div className="elementor-element elementor-element-5d69662 elementor-invisible elementor-widget elementor-widget-heading" data-id="5d69662" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;200&quot;}" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h2 className="elementor-heading-title elementor-size-default">Çalışmalarımızı görmek ister misiniz?</h2>		</div>
                          </div>
                          <div className="elementor-element elementor-element-ff6dcca elementor-align-center elementor-invisible elementor-widget elementor-widget-button" data-id="ff6dcca" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;400&quot;}" data-widget_type="button.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-button-wrapper">
                                <a href="/calismalar" className="elementor-button elementor-size-md" role="button">
                                  <span className="elementor-button-content-wrapper">
                                    <span className="elementor-button-text">Çalışmalar</span>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

            </div>
          </div>
        </div>
        <div className="post-tags">
        </div>
      </div>
      <section id="comments" className="comments-area">
      </section>{/* .comments-area */}
    </main>

  )
}
