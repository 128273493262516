import React from "react";
import {Home} from "./Pages/Home"
import {Services} from "./Pages/Services"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Portfolio } from "./Pages/Portfolio";
import { ContactForm } from "./Pages/ContactForm";
import { OfferForm } from "./Pages/OfferForm";
/* import  {AdminLanding}  from "./admin/pages/Login"; */
import { Login } from "./Pages/Login";
import 'react-toastify/dist/ReactToastify.css';
import { Nesli } from "./Pages/Nesli";
import { Quiz } from "./Pages/Quiz";
import PrivacyP from "./Pages/PrivacyPolicy";
import UserData from "./Pages/UserDataDeletion";

function App() {
  return (
    <Router>
    <Routes>
        
    <Route exact path="/" element={<Home />} /> 
    <Route path="/hizmetler" element={<Services />} /> 
    <Route path="/calismalar" element={<Portfolio />} /> 
    <Route path="/iletisim" element={<ContactForm />} /> 
    <Route path="/teklif" element={<OfferForm />} /> 
    <Route path="/nesli" element={<Nesli />} /> 
    <Route exact path="/admin" element={<Login />} /> 
    <Route exact path="/quiz/:name" element={<Quiz />} /> 
    <Route path="/privacy-policy" element={<PrivacyP />} /> 
    <Route path="/user-data-deletion" element={<UserData />} /> 

{/*     <Route exact path="/admin/panel" element={<AdminLanding />} /> 
 */}
    </Routes>
    </Router>
  );
}

export default App;
