import React from 'react';
import Helmet from 'react-helmet';
import { Footer } from '../Components/Footer';
import { Header } from '../Components/Header';
import {PrivacyPolicy} from '../Components/Policies';

const PrivacyP = () => {

    return (
        <React.Fragment>
          <Helmet>
            <title>Privacy Policy - Kuzey Software</title>
          </Helmet>
        <Header/>
        <PrivacyPolicy />
        <Footer/>
        </React.Fragment>
      )
}

export default PrivacyP;