import React from 'react'
import { Contact } from '../Components/Contact'
import { Footer } from '../Components/Footer'
import { Header } from '../Components/Header'
import {Helmet} from 'react-helmet'

export const ContactForm = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>İletişim Formu - Kuzey Software</title>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      </Helmet>
        <Header/>
        <Contact/>
        <Footer/>
    </React.Fragment>
  )
}
