import axios from 'axios';
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2'



export const Contact = () => {


    const [email,setEmail] = useState();
    const [name,setName] = useState();
    const [message,setMessage] = useState();
    const [token,setToken] = useState();

    const changeHandler = (value) => {
      setToken(value)
    };

    const submitHandler = async () => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      if(token === undefined){
        Toast.fire({
          icon: 'error',
          title: `Lütfen captcha doğrulamasını geçiniz!`
        })
      }else if(message === undefined || message.length < 20) {
        Toast.fire({
          icon: 'error',
          title: `Mesajınız 20 karakterden kısa olamaz!`
        })
      }else if(email === undefined || email.length < 4){
        Toast.fire({
          icon: 'error',
          title: `Lütfen mail adresinizi girin!`
        })
      }
      else if(name === undefined){
        Toast.fire({
          icon: 'error',
          title: `Lütfen isminizi girin!`
        })
      }
      else {
        const config = {
          headers: {
              'Access-Control-Allow-Origin': '*'
          }
      }
        const body = {"email" : email,"name" : name,"message" : message,"token" : token}
        const res = await axios.post("https://api.kuzeysoftware.com/mailer/contact", body, config )
        if(res.data == "Mail başarıyla gönderildi."){
          Toast.fire({
            icon: 'success',
            title: `Mesajınız başarıyla gönderildi!`
          })
        }else{
          Toast.fire({
            icon: 'error',
            title: `Captcha doğrulamasını geçemediniz!`
          })
        }
      }
    }

  return (
    <main id="content" className="site-main post-377 page type-page status-publish hentry" role="main">
    <div className="page-content">
      <div data-elementor-type="wp-page" data-elementor-id={377} className="elementor elementor-377">
        <div className="elementor-inner">
          <div className="elementor-section-wrap">
            <section className="elementor-section elementor-top-section elementor-element elementor-element-997dc28 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="997dc28" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-672ab55" data-id="672ab55" data-element_type="column">
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-element elementor-element-2294253 elementor-widget elementor-widget-spacer" data-id={2294253} data-element_type="widget" data-widget_type="spacer.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-939e142 elementor-invisible elementor-widget elementor-widget-heading" data-id="939e142" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;200&quot;}" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <h1 className="elementor-heading-title elementor-size-default">İletişim Formu</h1>		</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        
            <section className="elementor-section elementor-top-section elementor-element elementor-element-4c39a8c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4c39a8c" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
              <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-row">
                  <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6ce1f61" data-id="6ce1f61" data-element_type="column">
                    <div className="elementor-column-wrap elementor-element-populated">
                      <div className="elementor-widget-wrap">
                        <div className="elementor-element elementor-element-50da905 elementor-invisible elementor-widget elementor-widget-heading" data-id="50da905" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">Bize mesaj yollayın!</h2><br /><br />		</div>
                        </div>
                      
                        <div className="elementor-element elementor-element-c9ce744 elementor-invisible elementor-widget elementor-widget-metform" data-id="c9ce744" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:&quot;600&quot;}" data-widget_type="metform.default">
                          <div className="elementor-widget-container">
                            <div id="mf-response-props-id-388" data-editswitchopen data-erroricon="fas fa-exclamation-triangle" data-successicon="fas fa-check" data-messageposition="top" className="   mf-scroll-top-no">
                              <div className="formpicker_warper formpicker_warper_editable" data-metform-formpicker-key={388}>
                                <div className="elementor-widget-container">
                                  <div id="metform-wrap-c9ce744-388" className="mf-form-wrapper" data-form-id={388} data-action="../wp-json/metform/v1/entries/insert/388.json" data-wp-nonce="4ae3cee10c" data-form-nonce="0c24eb0c64" data-stop-vertical-effect />
                                  <div data-elementor-type="wp-post" data-elementor-id={388} className="elementor elementor-388">
                                    <div className="elementor-inner">
                                      <div className="elementor-section-wrap">
                                        <section className="elementor-section elementor-top-section elementor-element elementor-element-8a37600 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8a37600" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                          <div className="elementor-container elementor-column-gap-no">
                                            <div className="elementor-row">
                                              <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-27aeaa5f" data-id="27aeaa5f" data-element_type="column">
                                                <div className="elementor-column-wrap elementor-element-populated">
                                                  <div className="elementor-widget-wrap">
                                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-5a13b704 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5a13b704" data-element_type="section">
                                                      <div className="elementor-container elementor-column-gap-extended">
                                                        <div className="elementor-row">
                                                          <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-422e4bc" data-id="422e4bc" data-element_type="column">
                                                            <div className="elementor-column-wrap elementor-element-populated">
                                                              <div className="elementor-widget-wrap">
                                                                <div className="elementor-element elementor-element-1689e531 elementor-widget elementor-widget-mf-text" data-id="1689e531" data-element_type="widget" data-settings="{&quot;mf_input_name&quot;:&quot;mf-first-name&quot;}" data-widget_type="mf-text.default">
                                                                  <div className="elementor-widget-container">
                                                                    <div className="mf-input-wrapper">
                                                                      <input type="text" className="mf-input " id="mf-input-text-1689e531" name="mf-first-name" placeholder="İsminiz" onChange={(e) => setName(e.target.value)} />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                              
                                                        </div>
                                                      </div>
                                                    </section><br />
                                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-553e86e1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="553e86e1" data-element_type="section">
                                                      <div className="elementor-container elementor-column-gap-extended">
                                                        <div className="elementor-row">
                                                          <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7288dc94" data-id="7288dc94" data-element_type="column">
                                                            <div className="elementor-column-wrap elementor-element-populated">
                                                              <div className="elementor-widget-wrap">
                                                                <div className="elementor-element elementor-element-723d4805 elementor-widget elementor-widget-mf-email" data-id="723d4805" data-element_type="widget" data-settings="{&quot;mf_input_name&quot;:&quot;mf-email&quot;}" data-widget_type="mf-email.default">
                                                                  <div className="elementor-widget-container">
                                                                    <div className="mf-input-wrapper">
                                                                      <input type="email" className="mf-input" id="mf-input-email-723d4805" name="mf-email" placeholder='E-Mail Adresiniz' onChange={(e) => setEmail(e.target.value)}  />
                                                                   
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                     
                                                        </div>
                                                      </div>
                                                    </section><br />
                                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-19225d1f elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="19225d1f" data-element_type="section">
                                                      <div className="elementor-container elementor-column-gap-extended">
                                                        <div className="elementor-row">
                                                          <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4bbdd954" data-id="4bbdd954" data-element_type="column">
                                                            <div className="elementor-column-wrap elementor-element-populated">
                                                              <div className="elementor-widget-wrap">
                                                                <div className="elementor-element elementor-element-4462219f elementor-widget elementor-widget-mf-textarea" data-id="4462219f" data-element_type="widget" data-settings="{&quot;mf_input_name&quot;:&quot;mf-comment&quot;}" data-widget_type="mf-textarea.default">
                                                                  <div className="elementor-widget-container">
                                                                    <div className="mf-input-wrapper">
                                                                      <textarea className="mf-input mf-textarea" id="mf-input-text-area-4462219f" name="mf-comment" cols="20" rows="10" placeholder="Mesajınız" onChange={(e) => setMessage(e.target.value)}  />
                                                                    
                                                                     
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="elementor-element elementor-element-403ee486 mf-btn--center elementor-widget elementor-widget-mf-button" data-id="403ee486" data-element_type="widget" data-widget_type="mf-button.default">
                                                                  <div className="elementor-widget-container">
                                                                    <div className="mf-btn-wraper " data-mf-form-conditional-logic-requirement><br />
                                                                    <ReCAPTCHA sitekey='6Lcjq-8gAAAAAJ7sZC0gRbS1rX2CnuvDvqSgcZAP' onChange={changeHandler} /> <br/>
                                                                    <input id="reset" style={{visibility : 'hidden'}} type="reset" />
                                                                      <button onClick={() => submitHandler()} type="submit" className="metform-btn metform-submit-btn ">
                                                                        <span>Mesajı Gönder</span>
                                                                      </button><br /><br /><br />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </section>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      </div>
                                    </div>
                                  </div>
                              
                                </div>
                             
                            
                              </div>
                            </div>
                          </div>		</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section></div>
       
        </div>
      </div>
    </div>
    <div className="post-tags">
    </div>
    <section id="comments" className="comments-area">
    </section>{/* .comments-area */}
  </main>
  )
}
