import React from 'react'
import { Footer } from '../Components/Footer'
import { Offer } from '../Components/Offer'
import { Header } from '../Components/Header'
import {Helmet} from 'react-helmet'

export const OfferForm = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Teklif Formu - Kuzey Software</title>
                <script src="https://www.google.com/recaptcha/api.js" async defer></script>
            </Helmet>
            <Header />
            <Offer/>
            <Footer />
        </React.Fragment>

    )
}
